<template>
  <div>

    <loading :loading="isLoading"></loading>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <div class="img logo purple"></div>
              </div>

              <!--begin::Form-->
              <b-form class='form' @submit.stop.prevent='onSubmit'>

                <div
                  role='alert'
                  v-bind:class='{ show: errors.length }'
                  v-if="errors.length > 0"
                  class='alert fade alert-danger'
                >
                  <div class='alert-text' v-for='(error, i) in errors' :key='i'>
                    {{ error }}
                  </div>
                </div>

                <b-form-group
                  id='email-input-group'
                  :label="$t('FORMS.email')"
                  label-for='email-input'
                >
                  <b-form-input
                    class='form-control form-control-solid h-auto px-6'
                    id='email-input'
                    name='email-input'
                    v-model='$v.form.email.$model'
                    autocomplete="username"
                    :state="validateState('email')"
                    aria-describedby='input-email-live-feedback'
                  ></b-form-input>

                  <b-form-invalid-feedback id='input-email-live-feedback'>
                    {{ $t('AUTH.email_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id='password-input-group'
                  :label="$t('FORMS.password')"
                  label-for='password-input'
                >
                  <b-form-input
                    class='form-control form-control-solid h-auto px-6'
                    type='password'
                    id='password-input'
                    name='password-input'
                    autocomplete="current-password"
                    v-model='$v.form.password.$model'
                    :state="validateState('password')"
                    aria-describedby='input-password-live-feedback'
                  ></b-form-input>

                  <b-form-invalid-feedback id='input-password-live-feedback'>
                    {{ $t('FORMS.required') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div
                  class='d-flex flex-wrap justify-content-between align-items-center'
                >

                  <button
                    id="kt_login_signin_submit"
                    ref='kt_login_signin_submit'
                    class='btn btn-primary font-weight-bold px-9 mt-3 mx-auto font-size-3'
                    style="padding-right: 32px; padding-left: 32px;"
                  >
                    {{ $t('AUTH.sign_in') }}
                  </button>

                  <span
                    @click.self="loginM365"
                    class="btn btn-primary font-weight-bold px-9 mt-1 font-size-3"
                    style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                    v-if="isOffice365LoginEnabled"
                  >
                  <font-awesome-icon :icon="['fab', 'windows']"  />
                    {{ $t('AUTH.sign_in_m365') }}
                  </span>
                </div>
                  <router-link
                    :to="{ name: 'forgotPassword' }"
                    class='text-dark-60 text-hover-primary mr-2 mt-3'
                    id='kt_login_forgot'
                    style="display: block; font-size: small; text-align: center;"
                  >
                    {{ $t('AUTH.forgot_password') }}
                  </router-link>

                  <router-link
                    :to="{ name: 'register' }"
                    class='text-dark-60 text-hover-primary my-1 mr-2'
                    style="display: block; font-size: small; text-align: center;"
                  >
                  {{ $t('AUTH.sign_up_text') }}
                  </router-link>
                <!--end::Action-->
              </b-form>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  name: 'login',
  data () {
    return {
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      },
      msalClient: null
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    ...mapActions({
      getGeneralConfig: 'config/getGeneralConfig',
      getVpnConnectionStatus: 'config/getVpnConnectionStatus',
      login: 'auth/login',
      logout: 'auth/logout'
    }),
    async loginM365 () {
      return ApiService.query(URLS.API.AUTH.OAUTH_SIGNIN)
        .then(response => {
          if (response.status !== 200 || typeof response.data.messages === 'undefined') throw response
          window.location = response.data.messages
        })
        .catch(err => {
          console.log(err)
        })
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm () {
      this.form = {
        email: null,
        password: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      const email = this.$v.form.email.$model
      const password = this.$v.form.password.$model

      this.login({ username: email, password: password })
        // go to which page after successfully login
        .then(() => {
          if (this.isAuthenticated) {
            return this.getGeneralConfig()
          } else {
            throw new Error()
          }
        })
        .then(_ => {
          if (this.canSeeVpn) {
            this.getVpnConnectionStatus()

            setInterval(_ => {
              this.getVpnConnectionStatus()
            }, 15 * 1000)
          }

          this.$router.push({ name: 'home' })
        })
        .catch(_ => {})
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isLoading: 'auth/isLoading',
      errors: 'auth/getErrors',
      canSeeVpn: 'config/canSeeVpn'
    }),
    isOffice365LoginEnabled () { return process.env.VUE_APP_IS_M365_LOGIN_ENABLED === 'true' }
  }
}
</script>
